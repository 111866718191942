import React from "react";
import "../styles/Projects.css";
import "../styles/YouTubeVideos.css";
import FadeInSection from "./FadeInSection";

class YouTubeVideos extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      videos: []
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  componentDidMount() {
    const url = "https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCKQwvIlLeqy8qKC4tKgIUTw&maxResults=3&order=date&key=AIzaSyCi6hRI5MmW0oFNsHS01ffQRTHOTmcpMNU";
    fetch(url)
      .then(response => response.json())
      .then(data => this.setState({
        videos: data.items
      }));
  }

  render() {
    const youTubeVideos = this.state.videos ? this.state.videos : [];

    return (
        <div id="youtubevideos">
            <div className="section-header ">
                <span className="section-title">/ youtube-videos</span>
            </div>

            <div className="project-container">
              <ul className="projects-grid">
                {youTubeVideos.map((youtubeVideo, i) => (
                  <FadeInSection delay={`${i + 1}00ms`}>
                    <li className="projects-card">
                      <div className="card-title">
                        <a href={'https://www.youtube.com/watch?v=' + youtubeVideo.id.videoId} target="_blank" rel="noopener noreferrer">
                          <img src={youtubeVideo.snippet.thumbnails.medium.url} alt="Video Thumbnail"></img>
                        </a>
                      </div>
                      <div className="card-desc">
                        {youtubeVideo.snippet.title}
                      </div>
                    </li>
                  </FadeInSection>
                ))}
              </ul>
            </div>
        </div>
    );

  }
}

export default YouTubeVideos;