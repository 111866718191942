import React from "react";
import "../styles/Projects.css";
import "../styles/PublishedCourses.css";
import GitHubIcon from "@material-ui/icons/GitHub";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import FadeInSection from "./FadeInSection";

class PublishedCourses extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const courses = {
      "Creating a Spring Boot REST API with iPad Pro and Raspberry Pi 4": {
        desc:
          "An Apress and Springer Link course that will help you build a complex web application using only your iPad and Raspberry Pi.",
        techStack: "Java 11, Spring Boot 5, Postgres",
        link: "https://github.com/andreivisan/personalfinancesapi",
        open: "https://link.springer.com/video/10.1007/978-1-4842-8060-7"
      },
      "Securing a Spring Boot REST API Using Spring Security with iPad Pro and Raspberry Pi 4": {
        desc:
          "An Apress and Springer Link course which is the part 2 of the REST API, which handles security using only your iPad and Raspberry Pi.",
        techStack: "Java 11, Spring Boot 5, Postgres",
        link: "https://github.com/andreivisan/personalfinancesapi",
        open: "https://link.springer.com/video/10.1007/978-1-4842-8059-1"
      },
      "Front-End Development with iPad Pro and Raspberry Pi 4": {
        desc:
          "An Apress and Springer Link course which is the part 3 of the REST API, which handles the front-end of the REST API using only your iPad and Raspberry Pi.",
        techStack: "Java 11, Spring Boot 5, ReactJS, TailwindCSS, Postgres",
        link: "https://github.com/andreivisan/personalfinancesapi",
        open: "https://link.springer.com/video/10.1007/978-1-4842-8061-4"
      },
    };

    return (
      <div id="published-courses">
        <div className="section-header ">
          <span className="section-title">/ published-courses</span>
        </div>

        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(courses).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <span className="external-links">
                      <a className="github-icon" href={courses[key]["link"]} target="_blank" rel="noopener noreferrer">
                        <GitHubIcon
                          style={{
                            fontSize: 20,
                            color: "var(--lightest-grey)"
                          }}
                        ></GitHubIcon>
                      </a>
                      {courses[key]["open"] && (
                        <a className="open-icon" href={courses[key]["open"]} target="_blank" rel="noopener noreferrer">
                          <OpenInBrowserIcon
                            style={{
                              fontSize: 25,
                              color: "var(--lightest-slate)"
                            }}
                          ></OpenInBrowserIcon>
                        </a>
                      )}
                    </span>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{courses[key]["desc"]}</div>
                  <div className="card-tech">{courses[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default PublishedCourses;
