import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  } else {
    return {
      id: `vertical-tab-${index}`
    };
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    Swisscom: {
      jobTitle: "Senior Software Engineer @",
      duration: "DECEMBER 2022 - present",
      desc: [
        "Applied clean code practices and design patterns to develop readable, maintainable and scalable code.",
        "Improved existing code using SOLID design principles.",
        "Ensured quality with unit and integration tests and applying TDD.",
        "Technologies - Java 17, Spring 5, Jooq, Flyway, JavaScript, ReactJS, Mockito, PostgreSQL, Docker"
      ]
    },
    KPN: {
      jobTitle: "Senior Software Engineer @",
      duration: "MAY 2017 - DECEMBER 2022",
      desc: [
        "Oversee a team of eight software engineers and lead weekly discussions to brainstorm ideas and teach new concepts.",
        "Applied clean code practices and design patterns to develop readable, maintainable and scalable code for KPN.com.",
        "Helped in moving KPN.com from on-premise servers to AWS cloud.",
        "Improved existing code using SOLID design principles.",
        "Ensured quality with unit and integration tests and applying TDD.",
        "Technologies - Python, FastAPI, Java 8, Spring 4, JavaScript, AWS, Mockito, Oracle DB"
      ]
    },
    Adyen: {
      jobTitle: "Android Expert Engineer @",
      duration: "MAY 2014 - MAY 2017",
      desc: [
        "Design and develop the Adyen POS cash register application and reliable SDK libraries for integrating merchants.",
        "Direct contact with merchants to gather requirements and issues related to the Android and iOS POS cash register application",
        "Technologies - Android SDK, Android Async, Node.js"
      ]
    },
    "ING Bank": {
      jobTitle: "Lead Software Engineer @",
      duration: "DEC 2011 - MAY 2014",
      desc: [
        "Develop Full Stack internal ING Web Applications as well as internal Android Applications.",
        "Leading a team of six software engineers as well as having constant contact with our business partners to plan releases and make sure their requirements are accurate and easy to follow by the development team.",
        "Technologies - Java, Spring MVC, Dojo Toolkit, Android SDK, Oracle DB"
      ]
    },
    "OMNISOURCE TECHNOLOGIES": {
      jobTitle: "Senior Software Engineer @",
      duration: "SEPT 2010 - DEC 2011",
      desc: [
        "Analyse and design, develop and test complex Web Applications for companies like 1 & 1 and Colliers."
      ]
    },
    "ALTEN GROUP": {
      jobTitle: "Software Engineer @",
      duration: "APR 2008 - SEP 2010",
      desc: [
        "Analyse and design, develop and test complex Web Applications for companies like BNP Paribas and Allianz."
      ]
    },
    "STAR STORAGE": {
      jobTitle: "Software Engineer @",
      duration: "FEB 2007 - APR 2008",
      desc: [
        "Developed on IBM DB2 Content Manager for medium and big sized businesses."
      ]
    },
    "TOPTECH SRL": {
      jobTitle: "Intern Software Engineer @",
      duration: "JUNE 2006 - FEB 2007",
      desc: [
        "Provided Full Stack Web Development for banking clients."
      ]
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
