import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    return (
      <div id="intro">
        <Typist avgTypingDelay={120}>
          <span className="intro-title">
            {"hi, I am "}
            <span className="intro-name">{"Andrei"}</span>
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">I code and create stuff.</div>
          <div className="intro-desc">
            I'm a software engineer and YouTube content creator based in Switzerland. I have great
            interest in full-stack development, content creation, learning performance and learning
            methodologies and I love helping others learn better.
          </div>
          <a
            href="mailto:andrei.m.visan@gmail.com"
            className="intro-contact"
          >
            <EmailRoundedIcon></EmailRoundedIcon>
            {"  Say hello"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;
