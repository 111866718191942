import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import GitHubIcon from "@material-ui/icons/GitHub";
import FadeInSection from "./FadeInSection";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const projects = {
      "Coding Interview Preparation Guide": {
        desc:
          "An easy organized way to get REALLY good with using Data Structures and Algorithms. This is the basis of a YouTube course I create weekly episodes for.",
        techStack: "Python, Java",
        link: "https://github.com/andreivisan/interviews"
      },
      "Personal Finances": {
        desc:
          "The purpose of this project is to help the user create an overview over their personal expenses.",
        techStack: "Spring Boot 5, Java 11, ReactJS, Tailwind CSS, PostgreSQL",
        link: "https://github.com/andreivisan/personalfinancesapi"
      },
      "Code on iPad using Raspberry Pi 4": {
        desc:
          "Setup and Code on iPad using Raspberry Pi 4 and Code Server. This is a basis of a YouTube online tutorial I have filmed and posted.",
        link: "https://github.com/andreivisan/Raspberry-Pi-4-Code-Server"
      }
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">/ software-creations</span>
        </div>

        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <span className="external-links">
                      <a className="github-icon" href={projects[key]["link"]} target="_blank" rel="noopener noreferrer">
                        <GitHubIcon
                          style={{
                            fontSize: 20,
                            color: "var(--lightest-grey)"
                          }}
                        ></GitHubIcon>
                      </a>
                    </span>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
