import React from "react";
import "../styles/About.css";
import FadeInSection from "./FadeInSection";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1"
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
    const one = (
      <p>
        I am currently working as a <b>Senior Software Engineer</b> at{" "}
        <a href="https://swisscom.com" target="_blank" rel="noopener noreferrer" > Swisscom </a> in Switzerland. I am very passionate and 
        dedicated to my work. With my{" "} <b>15 years experience</b> as a{""} 
        <b>Software Engineer</b>, I have acquired skills and knowledge in numerous 
        fields including{" "} <b> web development, mobile development, 
        AWS architectures</b> and many others. I enjoy every step of the development 
        process, from client meetings and gathering requirements to creating 
        software architecture and development.
      </p>
    );
    const two = (
      <p>
        I'm into all sorts of <b>software development</b> that is challenging and requires
        creativity. My other areas of interest include <b>content creation</b>,{" "}
        <b>learning performance</b> and <b>learning methodologies</b>. I
        also like video and photo editing. In my free time, I read Cyberpunk and
        Hacker culture books.
      </p>
    );
    const desc_items = [one, two];

    const tech_stack = [
      "Java",
      "Spring Boot",
      "Python",
      "Fast API",
      "React.js",
      "Node.js",
      "Vue.js",
      "Android SDK",
      "Javascript ES6+",
      "HTML & CSS"
    ];

    var image = require("./assets/me1.jpg");

    return (
      <div id="about">
        <FadeInSection>
          <div className="section-header ">
            <span className="section-title">/ about me</span>
          </div>
          <div className="about-content">
            <div className="about-description">
              {desc_items}
              {"Some technologies I have been working with:"}
              <ul className="tech-stack">
                {tech_stack.map(function (tech_item, i) {
                  return (
                    <FadeInSection delay={`${i + 1}00ms`}>
                      <li>{tech_item}</li>
                    </FadeInSection>
                  );
                })}
              </ul>
            </div>
            <div className="about-image">
              <img src={image} alt="That's me"/>
            </div>
          </div>
        </FadeInSection>
      </div>
    );
  }
}

export default About;
